.dca_App {
    text-align: center;
}

.dca_Header {
    background-color: #5080b3;
    min-height: 6vh;
    color: white;
}

.dca_OverviewZone {
    max-width: 95vw;
    margin: 5vh auto;
}

.dca_SearchBarZone {
    margin-top: 2vh;
}

.dca_NoItemAlert {
    margin-top: 1vh;
    max-width: 40vw;
    text-align:center;
    margin: 2vh auto;
}

.dca_Title {
    margin: auto;
    line-height: 5.5vh;
    vertical-align: middle;
    font-size: calc(1.075rem + 1.5vw);
}

.dca_logo {
    max-height: 5.5vh;
    margin-top: 0.15vw;
}

.dca_logoInfra {
    float: right;
    display: block;
    margin-right: 1vw;

}

.dca_logoDCA {
    float: left;
    margin-left: 1vw;
}

#dca_SearchBar {
    max-height: 0.5vh;
}

#dca_SearchBarButton {
    margin-left: 1vh;
    background-color: #5080b3;
    min-height: 3vh;
    color: white;
}
